import { FaExclamationCircle, } from "react-icons/fa";
import { BsCheck2All, BsCheck2, BsClock, } from "react-icons/bs";
import { render } from "@testing-library/react";
// import {  useState } from "react";
// import PopupComponent from "./PopupComponent";

// const messageId = 123; // Example message ID
// const messageText = "This is a failed message";
const handleIconClick = (id, text) => {
  //const [check,setcheck] =useState(true)
  let check = true;
  const onClose = () => {
    console.log("false")
    check= false

    window.location.reload();
  }
  // alert(`Message ID: ${id}\nMessage Text: ${text}`);
  console.log("we")
  return (
    <div>
      {check ? (
        render(
          <div className="popup-overlay">
          <div className="popup-content">
            <h3 style={{ color: 'black' }}>Message Details</h3>
            <p><strong>ID:</strong> {id}</p>
            <p><strong>Text:</strong> {text}</p>
            <div style={{ display: 'flex', gap: '20px' }}>
              <button onClick={onClose} style={{ padding: '10px 20px' }}>Close</button>
              <button onClick={onClose} style={{ padding: '10px 20px' }}>Try Again</button>
            </div>
          </div>
        </div>
        )
      ) : (<div>
      </div>)
      }
    </div>
  )
};

export const renderStatusIcon = (status, message, id) => {


 
  
  const iconStyle = { fontSize: "17px", verticalAlign: "bottom" }; // Adjust font size to mimic WhatsApp ticks

  switch (status) {
    case "sent":
      return <BsCheck2 style={{ ...iconStyle, color: "#A6A6A6" }} title="Sent" />;  // Grey single tick

    case "delivered":
      return <BsCheck2All style={{ ...iconStyle, color: "#A6A6A6" }} title="Delivered" />;  // Grey double tick

    case "read":
      
      return <BsCheck2All style={{ ...iconStyle, color: "#34B7F1" }} title="Read" />;  // blue double tick

    case "failed":
     return (
    <FaExclamationCircle
      style={{ ...iconStyle, color: "red" }}
      title="Failed"
     // onClick={() => handleIconClick(id, message)} // Pass ID and text to alert
    />
  ); // Red exclamation

    default:
      return <BsClock style={{ ...iconStyle, color: "#A6A6A6" }} title="Pending" />;  // Grey clock
  }
};
